import { Footer, FooterGER, ButtonSubmit, ButtonBack, ScreenLoading } from '@components'
import { campaignService } from '@services'
import { Campaign } from '@utils'
import { IMAGES } from "@assets"
import {
  HTTP_CODE_OK,
  CAMPAIGN_IS_EXPIRED,
  SERIAL_CODE_DEFAULT,
  HTTP_CODE_IS_EXPIRED,
  HTTP_CODE_RATE_LIMIT_EXCEED,
  CAMPAIGN_SMS_TYPE,
  CAMPAIGN_SMS_SERIAL_TYPE,
  WIN_FLAG,
  LOSE_FLAG,
} from '@constants'

export default {
  name: 'SerialAuth',
  components: { Footer, FooterGER, ButtonSubmit, ButtonBack, ScreenLoading },
  props: {
    campaignUrlName: String,
    campaignUrl: String,
    campaignInfos: Object,
    campaignSettings: Object
  },
  data() {
    return {
      serial: {
        number: ''
      },
      canSubmit: false,
      serialNumberInvalid: false,
      messageErrors: '',
      isCallingProcessing: false,
      utmCampaign: '',
      utmMedium: '',
      utmSource: '',
      maskSerialNumber: 'AAAA-AAAA-AAAA-AAAA-AAAA-AAAA-AAAA-AAAA-AAAA-AAAA-AAAA-AAAA-AA',
      isRedemptionCampaign: false,

      // Data for great eastern rewards campaign
      isGreatEasternRewardsCampaign: false,
      IMAGES,
    }
  },
  methods: {
    async joinCampaign(e) {
      e.preventDefault()
      // Validate input
      if (this.validateBeforeSubmit()) {
        this.isCallingProcessing = true
        const serialCode = this.serial.number.replace(/-/g, '').toUpperCase();

        // Case campaign is redemption campaign
        if (this.isRedemptionCampaign) {
          return await this.lotteryPlayRedemption(serialCode);
        }

        await campaignService
          .auth('serial-auth', {
            campaign_url_name: this.campaignUrlName,
            campaign_url: this.campaignUrl,
            serial_code: serialCode,
            utm_campaign: this.utmCampaign,
            utm_medium: this.utmMedium,
            utm_source: this.utmSource
          })
          .then((res) => {
            const data = res.data
            if (
              data?.lottery_url !== undefined && res.status === HTTP_CODE_OK
            ) {
              if (data?.lottery_url === null) {
                Campaign.setSerialCodeViaSMS(serialCode);
                return this.$router.push({
                  name: 'campaign.auth.sms',
                  replace: true
                })
              }

              const lotteryUuid = data.lottery_url.substring(
                data.lottery_url.lastIndexOf('/') + 1
              )
              this.$router.push({
                name: 'lottery.game',
                params: { lotteryUuid },
                replace: true
              }).catch(() => {})
            } else {
              this.isFetchingPage = false;
              // Handle for campaign is expired
              if (data.status === HTTP_CODE_IS_EXPIRED) {
                this.$router.go({
                  name: 'campaign.home',
                  params: {
                    campaignUrlName: data.data.campaign_url_name,
                    campaignUrl: data.data.campaign_url
                  }
                })
              } else if (data.status === HTTP_CODE_RATE_LIMIT_EXCEED) {
                this.serialNumberInvalid = true,
                this.messageErrors = this.$t('verify_serial_exceeds_limit')
                this.canSubmit = false
              } else {
                this.serialNumberInvalid = true,
                this.messageErrors = this.$t('serial_invalid_code_msg')
                this.canSubmit = false
              }
            }
          })
        this.isCallingProcessing = false
      }
    },
    validateBeforeSubmit() {
      if (this.canSubmit) {
        if (this.serial.number.match(/^\S+$/)) {
          return true
        } else {
          this.$refs.serialNumber.focus()
          this.serialNumberInvalid = true
          this.messageErrors = this.$t('serial_wrong_format')
          this.canSubmit = false

          return false
        }
      } else {
        this.$refs.serialNumber.focus()

        return false
      }
    },
    async lotteryPlayRedemption(serialCode) {
      await campaignService
        .lotteryPlayRedemption({
          campaign_url_name: this.campaignUrlName,
          campaign_url: this.campaignUrl,
          serial_code: serialCode,
          utm_campaign: this.utmCampaign,
          utm_medium: this.utmMedium,
          utm_source: this.utmSource
        })
        .then((res) => {
          const lotteryUuid = res.data?.lottery_url
          if (res.data?.win_flag === WIN_FLAG) {
            if (res.data?.campaign_gift?.gift_url) {
              window.location.href = res.data.campaign_gift.gift_url
            } else {
              return  this.$router.push({
                name: 'campaign.error'
              });
            }
          } else if (res.data?.win_flag === LOSE_FLAG) {
            // Redirect to lost lottery game
            Campaign.clearLotteryCampaign(lotteryUuid);
            Campaign.setLotteryCampaign(lotteryUuid, res.data);
            let campaignUrlName = Campaign.getCampaignUrlName();
  
            return this.$router.push({
              name: 'lottery.game',
              params: { campaignUrlName, lotteryUuid }
            });
          } else {
            this.serialNumberInvalid = true,
            this.messageErrors = this.$t('serial_invalid_code_msg')
            this.canSubmit = false
          }
        });
        this.isCallingProcessing = false
    }
  },
  watch: {
    'serial.number'(value) {
      // Check End-user enter Serial code is it valid or not to show submit button
      if (value) {
        this.serialNumberInvalid = false

        return (this.canSubmit = value.length > 0)
      }
    }
  },
  computed: {
    createSetAppContainerStyles() {
      if (this.campaignSettings) {
        const objStyles = {
          '--app-container-bg-color': this.campaignSettings.preset_bg_color,
          '--app-container-text-color':
                this.campaignSettings.preset_text_color,
          '--app-container-font-family': Campaign.getLanguage().font_type
        }
        this.$setAppStyles(objStyles)
      }
    },
    redemptionBtnSubmitStyle() {
      return {
        'background-color': this.campaignSettings.preset_btn_bg_color
      }
    },
    disableNextProceedBtn() {
      return this.isCallingProcessing || !this.canSubmit || this.serial.number?.length == 0;
    }
  },
  created() {
    // Check campaign is expired
    const storageCampaignInfos = Campaign.getCampaignInfos();
    this.isRedemptionCampaign = storageCampaignInfos.is_redemption_campaign;
    this.isGreatEasternRewardsCampaign = Campaign.isGreatEasternCampaign();

    if (storageCampaignInfos.is_expired === CAMPAIGN_IS_EXPIRED) {
      this.$router.push({
        name: 'campaign.home',
        params: {
          campaignUrlName: this.campaignUrlName,
          campaignUrl: this.campaignUrl
        },
        replace: true
      })
    }

    if (storageCampaignInfos.campaign_type === CAMPAIGN_SMS_TYPE) {
      this.$router.push({
        name: 'campaign.auth.sms',
        replace: true
      })
    }

    if (storageCampaignInfos.campaign_type === CAMPAIGN_SMS_SERIAL_TYPE) {
      this.$router.push({
        name: 'campaign.auth.smsserial',
        replace: true
      })
    }

    // Get utm value
    this.utmCampaign = this.$route.query.utm_campaign
    this.utmMedium = this.$route.query.utm_medium
    this.utmSource = this.$route.query.utm_source
    // Get Serial code to fill in input
    this.serial.number = this.$route.query.code !== SERIAL_CODE_DEFAULT ? this.$route.query.code : ''
  }
}
